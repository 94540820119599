import Link from "@/components/Link";


export default function Page(props: any) {
	// window.location.href = "/services";
	if (window.location.pathname === "/") {
		window.location.href = "/services";

		return (
			<div className="text-xs p-2">Redirecting...</div>
		)
	}

	return (
		<div className="text-sm p-2 flex flex-col">
			<h2 className="font-bold">Hello!</h2>
			<p>How did you end up here? Please let us know!</p>
			<Link href="/services" text="back to services" />
		</div>
	)
}